import React from 'react';
import './sectiontexte.css';
import content from '../../../../content.json';

const SectionTexte = ({ language }) => {
  return (
    <div className='historySectionTexte'>
        <div className='textContainer'>
        <h1>{content.history[language].title2}</h1>
        <p>{content.history[language].textmobile2}</p>
        <p>{content.history[language].textmobile3}</p>
        </div>
    </div>
  );
};

export default SectionTexte;