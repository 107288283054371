import React, { useEffect, useState } from 'react';
import './landingspotifymobile.css';

const playlists = [
    { src: "https://open.spotify.com/embed/playlist/7zBJcELDGLG1VOCT8tOG5Q?utm_source=generator", title: "Playlist 1" },
    { src: "https://open.spotify.com/embed/playlist/4P1VCkVQyZfdhRZE0pkrR0?utm_source=generator", title: "Playlist 2" },
    { src: "https://open.spotify.com/embed/playlist/17xwHe4r6YWbS0Cu7pm8PD?utm_source=generator", title: "Playlist 3" },
    { src: "https://open.spotify.com/embed/playlist/44VT9sDj6aPxPdvgtmrgz2?utm_source=generator", title: "Playlist 4" },
    { src: "https://open.spotify.com/embed/playlist/4fuoSGsXUc93gCG3CgAbhS?utm_source=generator", title: "Playlist 5" },
    { src: "https://open.spotify.com/embed/playlist/4fbULkvkNciMNLJRHv5edU?utm_source=generator", title: "Playlist 6" },
    { src: "https://open.spotify.com/embed/playlist/2Fvn4fJQk787asZfpRehCi?utm_source=generator", title: "Playlist 7" },
    { src: "https://open.spotify.com/embed/playlist/4Vm8VmSbjeY3hNWvenJm0u?utm_source=generator", title: "Playlist 8" },
];

const LandingSpotifyMobile = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % playlists.length);
        }, 4000); // Change toutes les 4 secondes

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='superContainerMobileSpotify'>
            <h1>LA MUSIQUE</h1>
            <p>Parce que chaque voyage crée un souvenir, parce qu’on se souvient d’une atmosphère, d’un parfum ou d’une chanson… revivez votre séjour en musique.</p>
            <div className='playlistContainer' style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {playlists.map((playlist, index) => (
                    <div className='iframeContainer' key={index}>
                        <iframe
                            src={playlist.src}
                            title={playlist.title}
                            className='iframe'
                            width="99%"
                            height="450px"
                            frameBorder="0"
                            allow="encrypted-media"
                        ></iframe>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LandingSpotifyMobile;
