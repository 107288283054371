import React from 'react';
import './historylanding.css';
import content from '../../content.json';
import BIGIMG from '../../assets/History/big.gif';

const HistoryLanding = ({ language }) => {
  return (
    <div className='superContainer'>
      <div className='historyContainer'>
        <div className='leftPart'>
          <div className='textContainer'>
            <h1>{content.history[language].title1}</h1>

            <div className='firstBlockText'>
              <p>{content.history[language].text1}</p>
              <p>{content.history[language].text2}</p>
            </div>
            <h2>{content.history[language].title2}</h2>

            <div className='secondBlockText'>
              <p>{content.history[language].text3}</p>
              <p>{content.history[language].text4}</p>
            </div>
          </div>
        </div>

        <div className='rightPart'>
          <img src={BIGIMG} alt="Big" />
        </div>
      </div>
    </div>
  );
};

export default HistoryLanding;
