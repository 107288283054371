import React, { useRef, useEffect, useCallback } from 'react';
import './landingspotify.css';

const playlists = [
  { src: "https://open.spotify.com/embed/playlist/7zBJcELDGLG1VOCT8tOG5Q?utm_source=generator", title: "Playlist 1" },
  { src: "https://open.spotify.com/embed/playlist/4P1VCkVQyZfdhRZE0pkrR0?utm_source=generator", title: "Playlist 2" },
  { src: "https://open.spotify.com/embed/playlist/17xwHe4r6YWbS0Cu7pm8PD?utm_source=generator", title: "Playlist 3" },
  { src: "https://open.spotify.com/embed/playlist/44VT9sDj6aPxPdvgtmrgz2?utm_source=generator", title: "Playlist 4" },
  { src: "https://open.spotify.com/embed/playlist/4fuoSGsXUc93gCG3CgAbhS?utm_source=generator", title: "Playlist 5" },
  { src: "https://open.spotify.com/embed/playlist/4fbULkvkNciMNLJRHv5edU?utm_source=generator", title: "Playlist 6" },
  { src: "https://open.spotify.com/embed/playlist/2Fvn4fJQk787asZfpRehCi?utm_source=generator", title: "Playlist 7" },
  { src: "https://open.spotify.com/embed/playlist/4Vm8VmSbjeY3hNWvenJm0u?utm_source=generator", title: "Playlist 8" },
];

// Duplique les playlists
const duplicatedPlaylists = [...playlists, ...playlists, ...playlists];

const LandingSpotify = () => {
  const carouselRef = useRef(null);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = (e) => {
    startX.current = e.pageX - carouselRef.current.offsetLeft;
    scrollLeft.current = carouselRef.current.scrollLeft;
    carouselRef.current.addEventListener('mousemove', handleMouseMove);
    carouselRef.current.addEventListener('mouseup', handleMouseUp);
    carouselRef.current.addEventListener('mouseleave', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX.current) * 2;
    carouselRef.current.scrollLeft = scrollLeft.current - walk;

    // Logique de défilement infini
    if (carouselRef.current.scrollLeft <= 0) {
      carouselRef.current.scrollLeft = carouselRef.current.scrollWidth / 3;
    } else if (carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth / 3) {
      carouselRef.current.scrollLeft = 0;
    }
  };

  const handleMouseUp = () => {
    carouselRef.current.removeEventListener('mousemove', handleMouseMove);
    carouselRef.current.removeEventListener('mouseup', handleMouseUp);
    carouselRef.current.removeEventListener('mouseleave', handleMouseUp);
  };

  const handleWheel = useCallback((e) => {
    e.preventDefault();
    const delta = e.deltaX || e.deltaY;
    carouselRef.current.scrollLeft += delta * 0.5;

    // Logique de défilement infini
    if (carouselRef.current.scrollLeft <= 0) {
      carouselRef.current.scrollLeft = carouselRef.current.scrollWidth / 3;
    } else if (carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth / 3) {
      carouselRef.current.scrollLeft = 0;
    }
  }, []);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('wheel', handleWheel);
      return () => {
        carousel.removeEventListener('wheel', handleWheel);
      };
    }
  }, [handleWheel]);

  const handleIframeWheel = (e) => {
    e.stopPropagation();
    handleWheel(e);
  };

  return (
    <div
      className="carouselSpotifyLanding"
      ref={carouselRef}
      onMouseDown={handleMouseDown}
      style={{ overflow: 'hidden', display: 'flex' }}
    >
      <div className="carousel-inner-spotify" style={{ display: 'flex' }}>
        {duplicatedPlaylists.map((playlist, index) => (
          <div className="carousel-item" key={index} style={{ margin: '0 10px' }}>
            <iframe
              style={{ borderRadius: '12px', width: '100%', height: '100%' }}
              src={playlist.src}
              title={playlist.title}
              frameBorder="0"
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
              onWheel={handleIframeWheel}
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingSpotify;
