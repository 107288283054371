import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LOGO from '../../assets/LOGO-CHAPITRE-SIX.png';
import SUBLOGO from '../../assets/Deshistoires.svg';
import FLECHE from '../../assets/FLECHE.svg'; 
import './navbar.css';
import content from '../../content.json';
import { useFilter } from '../../FilterContext';

const Navbar = ({ changePage, changeLanguage, isTransparent }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false); // État pour le sous-menu

  const location = useLocation();
  const { selectedFilter, handleFilterChange } = useFilter();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setSubmenuOpen(false); // Ferme le sous-menu si le menu principal est ouvert
  };

  const closeMenuAndChangePage = (page) => {
    changePage(page);
    toggleMenu();
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const isFilterPage = location.pathname.includes('nos-actualites') || location.pathname.includes('les-hotels');
  const showSubLogo = !isFilterPage;

  return (
    <nav>
      <div className={`navContainer ${isTransparent ? 'transparent' : ''}`}>
        <div className="smallNavContainer">
          <div className="navRight">
            <div onClick={toggleMenu} className={`burger-icon ${isLoaded ? 'fade-in' : 'hidden'}`}>
              <svg width="38" height="20" viewBox="0 0 38 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                {!menuOpen && <rect x="38" y="20" width="38" height="0.999997" transform="rotate(-180 38 20)" fill="black" />}
                <rect x="38" y="10" width="38" height="0.999997" transform="rotate(-180 38 10)" fill="black" />
                {!menuOpen && <rect x="38" y="1" width="38" height="0.999997" transform="rotate(-180 38 1)" fill="black" />}
              </svg>
            </div>

            <Link className='containerLogo' to="/" onClick={() => closeMenuAndChangePage('home')}>
              <img src={LOGO} alt="Logo" className="Logo" />
            </Link>

            {showSubLogo && (
              <Link to="/" onClick={() => closeMenuAndChangePage('home')}>
                <img src={SUBLOGO} alt="Sublogo" className={`Sublogo ${isLoaded ? 'fade-in' : 'hidden'}`} />
              </Link>
            )}

            {isFilterPage && (
              <div className='filter'>
                <p 
                  onClick={() => handleFilterChange('Paris')} 
                  className={selectedFilter === 'Paris' ? 'active' : ''}
                >
                  Paris
                </p>
                <p 
                  onClick={() => handleFilterChange('Ailleurs')} 
                  className={selectedFilter === 'Ailleurs' ? 'active' : ''}
                >
                  Ailleurs
                </p>
                <p 
                  onClick={() => handleFilterChange('Tous')} 
                  className={selectedFilter === 'Tous' ? 'active' : ''}
                >
                  Tous
                </p>
              </div>
            )}
          </div>
          <button className={`booking ${isLoaded ? 'fade-in' : 'hidden'}`}>{content.home.fr.landing.button}</button>
        </div>
      </div>

      <div className={`menu ${menuOpen ? 'open' : ''}`}>
        <div className="subMenu">
          <button className='lesHotelsMenu' onClick={toggleSubmenu}>{content.home.fr.landing.submenu1}</button> 
          {submenuOpen && ( // Condition pour le rendu du sous-menu
            <div className='containerSubMenuLink'>
              <div className="submenuLinks">
                <Link className='lesHotelsRedirec' to="/les-hotels" onClick={() => closeMenuAndChangePage('les-hotels')}>TOUS LES HÔTELS</Link>
                <div className='ligneSubHotels'>
                  <a href="https://hotelhana-paris.com/" target="_blank" rel="noopener noreferrer">Hôtel Hana, Paris 2</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                <a href="https://maisonsaintonge.com/" target="_blank" rel="noopener noreferrer">Maison Saintonge, Paris 3</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                <a href="https://hoteldesacademies.fr/" target="_blank" rel="noopener noreferrer">Hôtel des Académies et des Arts, Paris 6</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                <a href="https://monsieurgeorge.com/" target="_blank" rel="noopener noreferrer">Monsieur George, Paris 8</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                <a href="https://www.buspalladium.com/" target="_blank" rel="noopener noreferrer">Le Bus Paladium, Paris 9</a>
                  <p>Prochainement</p>
                </div>
                <div className='ligneSubHotels'>
                <a href="https://www.monsieurcadet.com/" target="_blank" rel="noopener noreferrer">Monsieur Cadet, Paris 9</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                <a href="https://www.monsieuraristide.com/" target="_blank" rel="noopener noreferrer">Monsieur Aristide, Paris 18</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                <a href="https://laponche.com/" target="_blank" rel="noopener noreferrer">Hôtel Laponche, Saint-Tropez</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                <a href="https://capdantibes-beachhotel.com/" target="_blank" rel="noopener noreferrer">Cap d’Antibes Beach Hôtel, Cap d’Antibes</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                <a href="https://www.lafoliebarbizon.com/" target="_blank" rel="noopener noreferrer">La Folie Barbizon, Barbizon</a>
                  <p>Prochainement</p>
                </div>
                <div className='ligneSubHotels'>
                <a href="https://chaletsaintgeorges.com/fr" target="_blank" rel="noopener noreferrer">Chalet Saint-George, Megeve</a>
                  <p>Prochainement</p>
                </div>
              </div>
            </div>
          )}
          <Link to="/histoire" onClick={() => closeMenuAndChangePage('histoire')}>{content.home.fr.landing.submenu2}</Link>
          <Link to="/nos-actualites" onClick={() => closeMenuAndChangePage('nos-actualites')}>{content.home.fr.landing.submenu3}</Link>
        </div>
        <div className="bottomLine">
          <div className="langue">
            <button onClick={() => changeLanguage('en')}>EN</button>
            <button onClick={() => changeLanguage('fr')}>FR</button>
          </div>
          <button className="contact">contact</button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
