import React, { useState, useRef, useEffect, useCallback } from 'react';
import './hotelslanding.css'; 
import content from '../../content.json';
import IMAGE1 from '../../assets/Hotels/HOTELHANA.jpg';
import IMAGE2 from '../../assets/Hotels/CABH.jpg';
import IMAGE3 from '../../assets/Hotels/LaPonche.jpg';
import IMAGE4 from '../../assets/Hotels/MONSIEURARISTIDE.jpg';
import IMAGE5 from '../../assets/Hotels/ACADEMIES.jpg';
import IMAGE6 from '../../assets/Hotels/MEGEVE.jpg';
import IMAGE7 from '../../assets/Hotels/BARBIZON.jpg';
import IMAGE8 from '../../assets/Hotels/BUSPALLADIUM.jpg';
import IMAGE9 from '../../assets/Hotels/CADET.jpg';
import IMAGE10 from '../../assets/Hotels/GEORGE.jpg';
import IMAGE11 from '../../assets/Hotels/SAINTONGE.jpg';
import { useFilter } from '../../FilterContext'; 

const urlSite = [
    "https://hotelhana-paris.com/",
    "https://capdantibes-beachhotel.com/",
    "https://laponche.com/",
    "https://www.monsieuraristide.com/",
    "https://hoteldesacademies.fr/",
    "https://chaletsaintgeorges.com/fr",
    "https://www.lafoliebarbizon.com/",
    "https://www.buspalladium.com/",
    "https://www.monsieurcadet.com/",
    "https://www.monsieurgeorge.com/",
    "https://maisonsaintonge.com/",
];

const items = [
    { title: content.hotels.fr.hana.nom, address: content.hotels.fr.hana.adresse, image: IMAGE1, subtitle: content.hotels.fr.hana.titre, text: content.hotels.fr.hana.texte, category: 'paris' },
    { title: content.hotels.fr.antibes.nom, address: content.hotels.fr.antibes.adresse, image: IMAGE2, subtitle: content.hotels.fr.antibes.titre, text: content.hotels.fr.antibes.texte, category: 'ailleurs' },
    { title: content.hotels.fr.laponche.nom, address: content.hotels.fr.laponche.adresse, image: IMAGE3, subtitle: content.hotels.fr.laponche.titre, text: content.hotels.fr.laponche.texte, category: 'ailleurs' },
    { title: content.hotels.fr.aristide.nom, address: content.hotels.fr.aristide.adresse, image: IMAGE4, subtitle: content.hotels.fr.aristide.titre, text: content.hotels.fr.aristide.texte, category: 'paris' },
    { title: content.hotels.fr.academie.nom, address: content.hotels.fr.academie.adresse, image: IMAGE5, subtitle: content.hotels.fr.academie.titre, text: content.hotels.fr.academie.texte, category: 'paris' },
    { title: content.hotels.fr.chalet.nom, address: content.hotels.fr.chalet.adresse, image: IMAGE6, subtitle: content.hotels.fr.chalet.titre, text: content.hotels.fr.chalet.texte, category: 'ailleurs' },
    { title: content.hotels.fr.barbizon.nom, address: content.hotels.fr.barbizon.adresse, image: IMAGE7, subtitle: content.hotels.fr.barbizon.titre, text: content.hotels.fr.barbizon.texte, category: 'ailleurs' },
    { title: content.hotels.fr.paladium.nom, address: content.hotels.fr.paladium.adresse, image: IMAGE8, subtitle: content.hotels.fr.paladium.titre, text: content.hotels.fr.paladium.texte, category: 'paris' },
    { title: content.hotels.fr.cadet.nom, address: content.hotels.fr.cadet.adresse, image: IMAGE9, subtitle: content.hotels.fr.cadet.titre, text: content.hotels.fr.cadet.texte, category: 'paris' },
    { title: content.hotels.fr.george.nom, address: content.hotels.fr.george.adresse, image: IMAGE10, subtitle: content.hotels.fr.george.titre, text: content.hotels.fr.george.texte, category: 'paris' },
    { title: content.hotels.fr.saintonge.nom, address: content.hotels.fr.saintonge.adresse, image: IMAGE11, subtitle: content.hotels.fr.saintonge.titre, text: content.hotels.fr.saintonge.texte, category: 'paris' },
];

const HotelsLanding = () => {
    const { selectedFilter } = useFilter();
    const [paused, setPaused] = useState(false);
    const carouselRef = useRef(null);
    const startX = useRef(0);
    const scrollLeft = useRef(0);
    const [visibleItems, setVisibleItems] = useState(items);
    const duplicatedItems = [...items, ...items, ...items];
    const duration = Math.max(100, duplicatedItems.length * 2);
    const carouselWidth = `calc(${duplicatedItems.length} * 100%)`;
    const itemWidth = '439px'; 

    useEffect(() => {
        const filterItems = () => {
            if (selectedFilter === 'Tous') {
                setVisibleItems(items);
            } else {
                const filteredItems = items.filter(item => item.category === selectedFilter.toLowerCase());
                setVisibleItems(filteredItems);
            }
        };
        
        filterItems();
    }, [selectedFilter]);

    const handleMouseDown = (e) => {
        startX.current = e.pageX - carouselRef.current.offsetLeft;
        scrollLeft.current = carouselRef.current.scrollLeft;
        setPaused(true);
        carouselRef.current.addEventListener('mousemove', handleMouseMove);
        carouselRef.current.addEventListener('mouseup', handleMouseUp);
        carouselRef.current.addEventListener('mouseleave', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        const x = e.pageX - carouselRef.current.offsetLeft;
        const walk = (x - startX.current) * 2;
        carouselRef.current.scrollLeft = scrollLeft.current - walk;

        // Logique de défilement infini
        if (carouselRef.current.scrollLeft <= 0) {
            carouselRef.current.scrollLeft = carouselRef.current.scrollWidth / 3;
        } else if (carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth / 3) {
            carouselRef.current.scrollLeft = 0;
        }
    };

    const handleMouseUp = () => {
        carouselRef.current.removeEventListener('mousemove', handleMouseMove); 
        carouselRef.current.removeEventListener('mouseup', handleMouseUp);
        carouselRef.current.removeEventListener('mouseleave', handleMouseUp);
    };

    const handleImageMouseDown = (e) => {
        e.preventDefault(); 
    };

    const handleScroll = useCallback(() => {
        const carousel = carouselRef.current;
        if (carousel.scrollLeft >= carousel.scrollWidth / 3) {
            carousel.scrollLeft = 0; // Revenir au début
        } else if (carousel.scrollLeft <= 0) {
            carousel.scrollLeft = carousel.scrollWidth / 3; // Aller à la fin
        }
    }, []);

    const handleWheel = useCallback((e) => {
        e.preventDefault(); // Empêche le comportement par défaut de défilement de la page

        // Utilisez deltaX pour le défilement horizontal
        const delta = e.deltaX || e.deltaY;
        carouselRef.current.scrollLeft += delta * 0.5; // Ajustez ce facteur selon vos besoins

        // Logique pour assurer un défilement infini
        if (carouselRef.current.scrollLeft <= 0) {
            carouselRef.current.scrollLeft = carouselRef.current.scrollWidth / 3;
        } else if (carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth / 3) {
            carouselRef.current.scrollLeft = 0;
        }
    }, []);

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', handleScroll);
            carousel.addEventListener('wheel', handleWheel); // Ajout de l'écouteur pour le défilement
            return () => {
                carousel.removeEventListener('scroll', handleScroll);
                carousel.removeEventListener('wheel', handleWheel); // Nettoyage de l'écouteur
            };
        }
    }, [handleScroll, handleWheel]);

    return (
        <div
            className="carouselHotelsLanding"
            onMouseEnter={() => setPaused(true)}
            onMouseLeave={() => setPaused(false)}
            ref={carouselRef}
            onMouseDown={handleMouseDown}
            style={{ overflow: 'hidden', cursor: paused ? 'grab' : 'auto' }}
        >
            <div
                className={`carousel-inner ${paused ? 'paused' : ''}`}
                style={{ width: carouselWidth, animationDuration: `${duration}s`, display: 'flex' }}
            >
                {duplicatedItems.map((item, index) => {
                    const originalIndex = items.findIndex(i => i.title === item.title);
                    const isVisible = visibleItems.some(visibleItem => visibleItem.title === item.title);
                    
                    return (
                        <div 
                            className="carousel-item" 
                            key={index} 
                            style={{
                                width: itemWidth,
                                opacity: isVisible ? 1 : 0,
                                pointerEvents: isVisible ? 'auto' : 'none',
                                transition: 'opacity 0.5s ease, margin 0.5s ease',
                                margin: isVisible ? '0 10px' : '0',
                                display: isVisible ? 'flex' : 'none',
                            }}
                        >
                            <a 
                                href={urlSite[originalIndex]}
                                target="_blank"
                                rel="noopener noreferrer"
                                className='feuillet'
                                style={{ display: 'flex', flexDirection: 'column', textDecoration: 'none', color: 'inherit' }}
                            >
                                <div className='top'>
                                    <p className='title'>{item.title}</p>
                                    <p className='address'>{item.address}</p>
                                </div>
                                <img 
                                    src={item.image} 
                                    alt={item.title} 
                                    onMouseDown={handleImageMouseDown}
                                />
                                <div className='bottom'>
                                    <p>{item.subtitle}</p>
                                    <p className='texte'>{item.text}</p>
                                    <p className='voyager'>Voyager</p>
                                </div>
                            </a>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HotelsLanding;
