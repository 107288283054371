import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import HistoryLanding from './HistoryLanding/HistoryLanding';
import Landing from './mobile/History/Landing/Landing';
import ImageSection from './mobile/History/ImageSection/ImageSection';
import SectionTexte from './mobile/History/SectionTexte/SectionTexte'

const HistoryPage = ({ content, language }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>
      <Helmet>
        <title>Chapitre Six Hotels | Des Hotels et des Histoires</title>
        <meta name="description" content="Écrire et faire vivre des histoires, redorer de Belles Endormies, imaginer des atmosphères uniques pour accueillir ses voyageurs : c’est la raison d’être du groupe Chapitre Six." />
      </Helmet>
      <section className="section">
        {windowWidth > 1024 ? (
          <HistoryLanding content={content} language={language} />
        ) : (
          <Landing content={content} language={language} />
        )}
      </section>

      {windowWidth <= 1024 && (
        <>
          <section className="section">
            <ImageSection content={content} language={language} />
          </section>

          <section className="section">
            <SectionTexte content={content} language={language} />
          </section>
        </>
      )}
    </div>
  );
};

export default HistoryPage;
