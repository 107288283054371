import React, { useEffect, useRef } from 'react';
import './actualitylanding.css';
import content from '../../content.json';
import LANDING from '../../assets/Actualites/PHOTOPRINCIPALE.jpg';
import ARTICLE1 from '../../assets/Actualites/ACTUALITE13.jpg';
import ARTICLE2 from '../../assets/Actualites/ACTUALITE11.jpg';
import ARTICLE3 from '../../assets/Actualites/ACTUALITE12.jpg';
import ARTICLE4 from '../../assets/Actualites/ACTUALITE8.jpg';
import ARTICLE5 from '../../assets/Actualites/ACTUALITE3.jpg';
import ARTICLE6 from '../../assets/Actualites/ACTUALITE5.jpg';
import ARTICLE7 from '../../assets/Actualites/ACTUALITE10.jpg';
import ARTICLE8 from '../../assets/Actualites/ACTUALITE7.jpg';
import ARTICLE9 from '../../assets/Actualites/ACTUALITE9.jpg';
import ARTICLE10 from '../../assets/Actualites/ACTUALITE4.jpg';
import ARTICLE11 from '../../assets/Actualites/ACTUALITE6.jpg';
import ARTICLE12 from '../../assets/Actualites/ACTUALITE.jpg';
import ARTICLE13 from '../../assets/Actualites/ACTUALITE2.jpg';
import { useFilter } from '../../FilterContext';

const ActualityLanding = ({ language }) => {
  const { selectedFilter } = useFilter();
  const [currentImage, setCurrentImage] = React.useState(LANDING);
  const scrollableRef = useRef(null);

  const articles = [
    { ...content.actualites[language].actualite1, image: ARTICLE1 },
    { ...content.actualites[language].actualite2, image: ARTICLE2 },
    { ...content.actualites[language].actualite3, image: ARTICLE3 },
    { ...content.actualites[language].actualite4, image: ARTICLE4 },
    { ...content.actualites[language].actualite5, image: ARTICLE5 },
    { ...content.actualites[language].actualite6, image: ARTICLE6 },
    { ...content.actualites[language].actualite7, image: ARTICLE7 },
    { ...content.actualites[language].actualite8, image: ARTICLE8 },
    { ...content.actualites[language].actualite9, image: ARTICLE9 },
    { ...content.actualites[language].actualite10, image: ARTICLE10 },
    { ...content.actualites[language].actualite11, image: ARTICLE11 },
    { ...content.actualites[language].actualite12, image: ARTICLE12 },
    { ...content.actualites[language].actualite13, image: ARTICLE13 },
  ];

  const filteredArticles = articles.filter(article =>
    selectedFilter === 'Tous' || article.class === selectedFilter.toLowerCase()
  );

  const handleScroll = (event) => {
    event.stopPropagation(); // Empêche le scroll de remonter à la page
  };

  useEffect(() => {
    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener('wheel', handleScroll, { passive: false });
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  return (
    <div className='actualityLandingContainer'>
      <div className='actualityLandingSmallContainer'>
        <div className='separateContainer'>
          <div className='actualityLandingTextBlock'>
            <h1>{content.actualites[language].bloctext.title}</h1>
            <p>{content.actualites[language].bloctext.text}</p>
            <p className='subTexte'>{content.actualites[language].bloctext.subtext}</p>
          </div>

          <div className='actualityLandingActualiteContainer'>
            <div className='scrollableContent' ref={scrollableRef}>
              {filteredArticles.length > 0 ? (
                filteredArticles.map((article, index) => (
                  <div 
                    key={index} 
                    className={`actualityLandingUniqueContainer ${article.class}`}
                    onMouseEnter={() => setCurrentImage(article.image)}
                    onMouseLeave={() => setCurrentImage(LANDING)}
                  >
                    <h2>{article.titre}</h2>
                    <p className='date'>{article.date}</p>
                    <p>{article.texte}</p>
                  </div>
                ))
              ) : (
                <p>Aucun article trouvé</p>
              )}
            </div>
          </div>
        </div>

        <div className='testactuality'>
          <img src={currentImage} alt="Actualité" className='actualityImgLanding' />
        </div>
      </div>
    </div>
  );
};

export default ActualityLanding;
