import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; // Import des styles Slick de base
import 'slick-carousel/slick/slick-theme.css'; // Import des styles Slick thème
import './landingmobileactu.css'; // Ton propre fichier de styles
import ACTU from '../../../assets/Actualites/mobile/ACTU.jpg';
import ACTU1 from '../../../assets/Actualites/mobile/ACTUALITE14.jpg';
import ACTU2 from '../../../assets/Actualites/mobile/ACTUALITE12.jpg';
import ACTU3 from '../../../assets/Actualites/mobile/ACTUALITE13.jpg';
import ACTU4 from '../../../assets/Actualites/mobile/ACTUALITE9.jpg';
import ACTU5 from '../../../assets/Actualites/mobile/ACTUALITE4.jpg';
import ACTU6 from '../../../assets/Actualites/mobile/ACTUALITE6.jpg';
import ACTU7 from '../../../assets/Actualites/mobile/ACTUALITE11.jpg';
import ACTU8 from '../../../assets/Actualites/mobile/ACTUALITE8.jpg';
import ACTU9 from '../../../assets/Actualites/mobile/ACTUALITE10.jpg';
import ACTU10 from '../../../assets/Actualites/mobile/ACTUALITE5.jpg';
import ACTU11 from '../../../assets/Actualites/mobile/ACTUALITE7.jpg';
import ACTU12 from '../../../assets/Actualites/mobile/ACTUALITE2.jpg';
import ACTU13 from '../../../assets/Actualites/mobile/ACTUALITE3.jpg';

import content from '../../../content.json';

// Flèche Gauche
const ArrowLeft = (props) => (
  <button className='slick-arrow slick-prev' {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
      <path d="M11 1L0.999999 8L6 11.5L11 15" stroke="black"/>
    </svg>
  </button>
);

// Flèche Droite
const ArrowRight = (props) => (
  <button className='slick-arrow slick-next' {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
      <path d="M1 15L11 8L6 4.5L0.999999 0.999999" stroke="black"/>
    </svg>
  </button>
);

const images = [
  { src: ACTU, textKey: 'presentationmobile' }, 
  { src: ACTU1, textKey: 'actualite1' }, 
  { src: ACTU2, textKey: 'actualite2' }, 
  { src: ACTU3, textKey: 'actualite3' },
  { src: ACTU4, textKey: 'actualite4' }, 
  { src: ACTU5, textKey: 'actualite5' }, 
  { src: ACTU6, textKey: 'actualite6' },   
  { src: ACTU7, textKey: 'actualite7' }, 
  { src: ACTU8, textKey: 'actualite8' }, 
  { src: ACTU9, textKey: 'actualite9' }, 
  { src: ACTU10, textKey: 'actualite10' }, 
  { src: ACTU11, textKey: 'actualite11' }, 
  { src: ACTU12, textKey: 'actualite12' }, 
  { src: ACTU13, textKey: 'actualite13' }
];

const LandingMobileActu = ({ language }) => { // Recevoir la langue comme prop
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleAfterChange = (index) => {
    setCurrentIndex(index);
  };

  const currentText = content.actualites[language][images[currentIndex].textKey] || {}; // Utiliser la langue active

  // Configuration du carrousel
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '10%',
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '10%',
        },
      },
    ],
  };

  // Vérifie si l'image actuelle est ACTU
  const isActuImage = images[currentIndex].src === ACTU;

  return (
    <div className='landingMobileActu'>
        <div className='mobileActuBigContainer'>
            <div className='slider-text'>
                {isActuImage && <h1>{content.actualites[language].bloctext.title}</h1>} {/* Utiliser la langue active */}
                <h2>{currentText.titre}</h2>
                <p>{currentText.texte}</p>
                <p className='date'>{currentText.date}</p>
            </div>
            <Slider {...settings}>
                {images.map((image, index) => (
                <div key={index} className='slide-item'>
                    <img src={image.src} alt={`Slide ${index}`} />
                </div>
                ))}
            </Slider>
        </div>
    </div>
  );
};

export default LandingMobileActu;
